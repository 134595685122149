@use 'ds/colors/bg-colors';
@use 'ds/colors/brand-colors';
@use 'ds/colors/text-colors';
@use 'ds/typography';

.mat-tooltip {
  /**
   * Tooltip
   * https://docs.checklistfacil.com.br/link/597#bkmrk-input
   */
  @include typography.caption($important: true);
  background-color: #212121 !important;
  color: #fff !important;
  text-align: center;
  word-break: break-word;
}

.mat-dialog-title {
  display: flex !important; // JIT coloca estilos numa ordem que sobreescreve o overrides :(
  align-items: center;

  .dialog-title {
    @include typography.headline-3();
    color: text-colors.$high-emphasis;
  }
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button {
  .mat-button-wrapper {
    @include typography.button();
  }

  min-height: 40px;
  align-items: center;
}

.mat-tab-list {
  .mat-tab-label,
  .mat-tab-link {
    opacity: 1;
  }

  .mat-tab-disabled {
    &.mat-tab-label .mat-tab-label-content,
    &.mat-tab-link {
      color: text-colors.$disabled;
    }
  }

  .mat-tab-label-active {
    &.mat-tab-link,
    .mat-tab-label-content {
      color: brand-colors.$default;
    }
  }

  .mat-tab-label-content,
  .mat-tab-link {
    @include typography.button();

    color: text-colors.$medium-emphasis;
  }
}
