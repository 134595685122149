/**
 * Outline
 * https://docs.checklistfacil.com.br/link/589#bkmrk-outline
 *
 * bordas e separadores
 */
$outline: #e0e0e0;

/**
 * Error
 * https://docs.checklistfacil.com.br/link/589#bkmrk-error
 *
 * Se precisar definir a cor de um texto, use ds/colors/text-colors
 */
$error: #b00020;
