@use '~@angular/material' as mat;
@use 'ds/colors/text-colors';
@use 'ds/colors/brand-colors';
@use 'ds/typography';
@use 'ds/colors';

$cl-theme-primary: mat.define-palette((
  200: brand-colors.$lighter,
  500: brand-colors.$default,
  800: brand-colors.$darker,
  contrast: (
    200: #000,
    500: #fff,
    800: #fff,
  ),
), 500, 200, 800);

$cl-theme-accent: mat.define-palette((
  200: #ffc647,
  500: #ff9500,
  800: #c66600,
  contrast: (
    200: #000,
    500: #000,
    800: #fff,
  ),
), 500, 200, 800);
$cl-theme-warn: mat.define-palette((
  800: colors.$error,
  contrast: (
    800: #fff,
  ),
), 800, 800, 800);

// Create the theme object (a Sass map containing all of the palettes).
$cl-light-theme: mat.define-light-theme(
  $cl-theme-primary,
  $cl-theme-accent,
  $cl-theme-warn
);

// Our mixins, functions and variables

$cl-typography-primary: mat.define-typography-config(
  $font-family: 'Roboto, sans-serif',
  $subheading-1:
    mat.define-typography-level(16px, 21px, 400, $letter-spacing: 0.15px),
  $subheading-2:
    mat.define-typography-level(14px, 19px, 700, $letter-spacing: 0.09px),
  $button: mat.define-typography-level(14px, 19px, 500, $letter-spacing: 1.25px),
  $body-1: mat.define-typography-level(16px, 24px, 400, $letter-spacing: 0.5px),
  $body-2: mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.25px),
  $caption: mat.define-typography-level(12px, 16px, 400, $letter-spacing: 0.4px),
);
/**
 * $cl-typography-secondary, $cl-typography-monospace, $cl-typography-serif
 * DEPRECATED
 * Variável mantida apenas para evitar quebra durante a transição.
 * @TODO: revisar shared-main-menu-notification
 */
$cl-typography-secondary: $cl-typography-primary;
$cl-typography-serif: $cl-typography-primary;
$cl-typography-monospace: $cl-typography-primary;

$cl-background-color: #e0e0e0;
$cl-border-color: #e0e0e0;
$cl-divider-color: #dcdcdc;
$cl-divider-light-color: rgba(33, 33, 33, 0.08);

// DEPRECATED
@function cl-font-size($config, $level) {
  @return mat.font-size($config, $level);
}
// DEPRECATED
@function cl-font-family($config) {
  @return mat.font-family($config);
}
// DEPRECATED
@function cl-font-weight($config, $level) {
  @return mat.font-weight($config, $level);
}
// DEPRECATED
@function cl-line-height($config, $level) {
  @return mat.line-height($config, $level);
}
// DEPRECATED
@mixin cl-font-style($config, $level) {
  @if $level == 'overline' {
    @include mat.typography-level($config, 'caption');

    letter-spacing: 1.5px;
    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
    text-transform: uppercase;
  } @else {
    @include mat.typography-level($config, $level);
  }
}

@function cl-text-color($style: 'primary') {

  @if $style == 'secondary' {
    @return text-colors.$medium-emphasis;
  }
  @if $style == 'disabled' {
    @return text-colors.$disabled;
  }
  @if $style == 'icon' {
    @return text-colors.$medium-emphasis;
  }
  @if $style == 'link' {
    @return text-colors.$link;
  }

  @return text-colors.$high-emphasis
}

@function cl-text-light-color($style: 'primary') {
  @if $style == 'secondary' {
    @return rgba(255, 255, 255, 0.6);
  }
  @if $style == 'disabled' {
    @return rgba(255, 255, 255, 0.38);
  }
  @if $style == 'icon' {
    @return rgba(255, 255, 255, 0.5);
  }

  @return rgba(255, 255, 255, 0.87);
}

// DEPRECATED
@function cl-theme-color($theme: 'primary', $variant: 'default') {

  @if $theme == 'warn' {
    @return colors.$error;
  }

  @if($variant == 200) {
    @return brand-colors.$lighter;
  }
  @if($variant == 800) {
    @return brand-colors.$darker;
  }
  @return brand-colors.$default;
}
