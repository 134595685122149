@use 'ds/colors/text-colors';

html {
  height: 100%;
  position: fixed;
  overflow: hidden;
  width: 100%;
}

body {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin: 0;
}

.root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.obw > * {
  box-sizing: border-box;
}

.obw {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  overflow: hidden;
  overflow-y: scroll;
  right: 0;
  top: 0;
}

.obw__content {
  display: block;
  margin: 0 auto;
  width: 800px;
}

.obw-illustration {
  background-color: #d3d3d3;
  display: block;
  margin: 41px auto;
  min-height: 249px;
  min-width: 352px;
}

.obw-warning-text {
  color: text-colors.$medium-emphasis;
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
}

.obw-argument {
  background-color: #f2f2f2;
  border-radius: 8px;
  display: block;
  margin: 24px auto 0;
  width: 662px;
  padding: 8px 10px 19px 16px;
}
.obw-argument__question {
  color: text-colors.$medium-emphasis;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0 0 12px 0;
}
.obw-argument__answer + .obw-argument__question {
  margin-top: 32px;
}
.obw-argument__answer {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0 0 8px 0;
  text-indent: 24px;
}
.obw-argument__answer--short {
  font-style: italic;
  margin: 20px 0 10px;
  text-indent: 0;
}
.obw-argument__answer--short::before {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 4px;
  margin: 0 10px;
  vertical-align: middle;
  width: 4px;
}
//.obw-argument__answer:first-line,
//.obw-argument__answer::first-line {
//  margin-left: 24px;
//}
.obw-argument__list {
  margin: 0;
  padding: 0 0 0 26px;
}
.obw-argument__list-item {
  font-size: 16px;
  letter-spacing: 0.5px;
}
.obw-argument__list-item + .obw-argument__list-item {
  margin-top: 8px;
}
.obw-more {
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 41px auto 36px;
  text-align: center;
}
.obw-more__text {
  color: text-colors.$high-emphasis;
  font-weight: 500;
}
.obw-more__link:link {
  color: text-colors.$link;
}
.obw-more__link--active {
  color: text-colors.$disabled;
}

.obw-more-info {
  display: none;
  padding-bottom: 36px;
}
