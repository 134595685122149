/* style overrides for angular material components */
@use 'ds/colors/text-colors';
@use 'ds/colors/brand-colors';
@import 'theme';

/** TEXTFIELD OVERRIDES */
.mat-hint {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: -2px;
}
.mat-form-field.mat-focused .mat-hint {
  color: brand-colors.$default;
}
.mat-form-field-appearance-fill {
  .mat-form-field-wrapper .mat-form-field-flex {
    border-radius: 8px;
    min-height: 48px;
    padding-top: 2px;
    background-color: rgba(0, 0, 0, 0.07);

    button mat-icon {
      color: text-colors.$medium-emphasis;
    }

    button:disabled mat-icon {
      color: text-colors.$disabled;
    }

    .mat-form-field-infix {
      .mat-form-field-label {
        @include cl-font-style($cl-typography-primary, 'subheading-2');

        font-weight: 500;
        top: 1.35em;
      }

      input {
        @include cl-font-style($cl-typography-primary, 'body-2');

        font-weight: 400;
        color: text-colors.$high-emphasis;
      }

      input:disabled {
        color: text-colors.$disabled;
      }
    }

    //.mat-form-field-suffix button {
    //  max-height: 20px;
    //}
  }

  &.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .mat-form-field-underline {
    display: none;
  }

  &:hover {
    &:not(.cl-condensed-input) .mat-form-field-underline {
      display: block;
    }

    &:not(.mat-form-field-disabled):not(.cl-condensed-input)
      .mat-form-field-wrapper
      .mat-form-field-flex {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(.mat-focused) .mat-form-field-ripple {
      display: none;
    }
  }

  &.mat-form-field-can-float.mat-form-field-should-float:not(.mat-focused)
    .mat-form-field-label {
    color: text-colors.$medium-emphasis;
  }

  &.mat-focused,
  &.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-underline {
      display: block;
    }

    &:not(.mat-form-field-disabled)
      .mat-form-field-wrapper
      .mat-form-field-flex {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 2px;
  }

  &.cl-condensed-input {
    &.mat-form-field-disabled
      .mat-form-field-wrapper
      .mat-form-field-flex
      .mat-form-field-infix
      input::placeholder {
      color: text-colors.$disabled;
    }

    .mat-form-field-wrapper {
      padding-bottom: 15px;

      .mat-form-field-flex {
        height: 32px;
        padding-top: 0;
        padding-right: 0;
        padding-left: 8px;

        .mat-form-field-infix {
          border-top: 0;
          padding-top: 0;
          padding-bottom: 0;
          top: 0;

          input {
            @include cl-font-style($cl-typography-primary, 'body-2');

            font-weight: 400;
            color: text-colors.$high-emphasis;

            height: 32px;
            margin-top: 0;

            &::placeholder {
              @include cl-font-style($cl-typography-primary, 'subheading-2');

              font-weight: 500;
              color: text-colors.$medium-emphasis;
            }
          }
        }

        .mat-form-field-prefix button {
          height: 32px;
        }

        .mat-form-field-suffix button {
          height: 32px;
        }
      }

      .mat-form-field-underline {
        top: 32px;
      }
    }
  }
}

.mat-form-field-appearance-fill:not(.mat-form-field-suffix--original) {
  .mat-form-field-wrapper .mat-form-field-flex {
    .mat-form-field-suffix button {
      max-height: 20px;
    }
  }
}
/* TEXTAREA */
cl-form-textarea
  .mat-form-field-appearance-fill
  .mat-form-field-wrapper
  .mat-form-field-flex {
  height: auto;
}
