/**
 * Background colors
 * https://docs.checklistfacil.com.br/link/589#bkmrk-background
 */
$base: #fff;
$first-layer: #fafafa;
$second-layer: #f2f2f2;
$third-layer: rgba(33, 33, 33, 0.08);
$overlay: rgba(0, 0, 0, 0.6);
$overlay-caption: #424242;
