@use '~@angular/material' as mat;
@use 'ds/colors/text-colors' as text-colors;
@use 'ds/colors/bg-colors' as bg-colors;
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
// @import "~material-design-icons/iconfont/material-icons.css";
@import 'theme';

@import '~quill/dist/quill.core.css';

@include mat.core();
@include mat.all-component-themes($cl-light-theme);
@import 'overrides';

:root {
  --brand-color-default: #00AC67;
  --brand-color-lighter: #00e98c;
  --brand-color-darker: #006f42;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

#root {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
::ng-deep * {
  box-sizing: border-box;
}

.cl-custom-border-radius .mat-dialog-container {
  border-radius: 8px;
}

a {
  color: text-colors.$link;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.cl-backdrop-60 {
  background: bg-colors.$overlay;
}

.box-sizing-border-box-container > * {
  box-sizing: border-box;
}
